import React, { useState } from 'react'
import {
    message,
    Form,
    Input,
    Row,
    Col,
    Divider,
    Button,
    InputNumber,
    Select
} from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

import { useApolloClient } from '@apollo/client'

import MediaUploader from '@cms/core/components/MediaUploader/graphql'
import { CrudModal, useCrudForm } from '@cms/core/components/Crud'

import { addToList } from '@cms/watch/graphql/utils'
import { GET_WATCH_EXPERT } from '@cms/watch/graphql/queries/watchExpert'
import {
    CREATE_WATCH_EXPERT,
    UPDATE_WATCH_EXPERT
} from '@cms/watch/graphql/mutations/watchExpert'

const { Option } = Select

const formatData = ({ ...data }) => {
    data.links =
        data.links?.map((o) => {
            return { type: o.type, url: o.url }
        }) || []

    return data
}

const getData = (data) => {
    return {
        ...data
    }
}

const WatchExpertsModal = ({ params, ...props }) => {
    const client = useApolloClient()

    const [{ form, isEdit, getError, formUpdate }, modalProps] = useCrudForm({
        relateds: [],
        getData: async (id) => {
            if (!id) return

            const { data } = await client.query({
                query: GET_WATCH_EXPERT,
                variables: { id }
            })

            return getData(data.WatchExpert)
        },
        createData: async (formData) => {
            await client.mutate({
                mutation: CREATE_WATCH_EXPERT,
                update: addToList({
                    list: 'WatchExperts',
                    Type: 'WatchExpert'
                }),
                variables: { ...formatData(formData) }
            })

            message.success('Especialista cadastrado com sucesso!')
        },
        updateData: async (id, formData) => {
            await client.mutate({
                mutation: UPDATE_WATCH_EXPERT,
                variables: {
                    id,
                    ...formatData(formData),
                    image: formData.image
                }
            })

            message.success('Especialista atualizado com sucesso!')
        }
    })

    const [linkTypes] = useState([
        'Linkedin',
        'Facebook',
        'Twitter',
        'Instagram',
        'TikTok',
        'Outros'
    ])

    const initialValues = {
        links: [{ type: 'Linkedin', url: '' }]
    }

    return (
        <CrudModal
            width={600}
            title={isEdit ? 'Editar Especialista' : 'Novo Especialista'}
            {...modalProps}
            {...props}
        >
            <Form layout={'vertical'} initialValues={initialValues} form={form}>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="Nome"
                            name="firstName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Nome é obrigatório!'
                                }
                            ]}
                            {...getError('firstName')}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="Sobrenome"
                            name="lastName"
                            {...getError('lastName')}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="Cargo"
                            name="office"
                            {...getError('office')}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="Foto (proporção 9 x 16)"
                            name="image"
                            {...getError('image')}
                        >
                            <MediaUploader
                                options={{
                                    optimize: {
                                        resize: {
                                            width: 500,
                                            height: 500
                                        },
                                        quality: 80
                                    }
                                }}
                                type="image"
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item label="Bio" name="bio" {...getError('bio')}>
                    <Input.TextArea rows={4} />
                </Form.Item>

                <Form.Item
                    label="Prioridade (Ordena os especialistas do maior para o menor)"
                    name="priority"
                    {...getError('priority')}
                >
                    <InputNumber style={{ width: '100%' }} />
                </Form.Item>

                <Divider orientation="left">Empresa</Divider>

                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="Nome da empresa"
                            name={['company', 'name']}
                            {...getError('first_name')}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="Logo da empresa"
                            name={['company', 'image']}
                            {...getError('lastname')}
                        >
                            <MediaUploader type="svg" />
                        </Form.Item>
                    </Col>
                </Row>

                <Divider orientation="left">Links</Divider>

                <Form.List name="links">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field, index) => (
                                <div key={field.key}>
                                    <Row gutter={24} align="middle">
                                        <Col span={8}>
                                            <Form.Item
                                                label="Origem"
                                                name={[field.name, 'type']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Origem é obrigatório!'
                                                    }
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    filterOption={(
                                                        input,
                                                        option
                                                    ) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .indexOf(
                                                                input.toLowerCase()
                                                            ) >= 0
                                                    }
                                                >
                                                    {linkTypes.map((o) => (
                                                        <Option key={o}>
                                                            {o}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={14}>
                                            <Form.Item
                                                {...field}
                                                label="Url"
                                                name={[field.name, 'url']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Url é obrigatório!'
                                                    }
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                            <MinusCircleOutlined
                                                style={{ marginTop: 20 }}
                                                onClick={() =>
                                                    remove(field.name)
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => {
                                        add({})
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    <PlusOutlined /> Adicionar
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form>
        </CrudModal>
    )
}

export default WatchExpertsModal
