import { useApolloClient, useQuery } from '@apollo/client'
import { Layout } from 'antd'
import _get from 'lodash/get'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import Content from '@cms/core/components/Content'
import { CrudColumns, CrudList } from '@cms/core/components/Crud'
import {
    GET_EVENT_FORM,
    GET_EVENT_FORM_DATA
} from '@cms/events/graphql/queries/form'
import { formatField } from '@td/commons/fields/formatter'
import { REQUEST_REPORT } from '@cms/events/graphql/mutations/reports'
import { addToList } from '@cms/core/graphql/utils'

const ModalContent = ({ eventId }) => {
    const { id } = useParams()
    const [eventData, setEventData] = useState()
    const [columns, setColumns] = useState()
    const client = useApolloClient()
    const history = useHistory()

    const query = useQuery(GET_EVENT_FORM_DATA, {
        variables: { formId: id, first: 25 }
    })

    const queryForm = useQuery(GET_EVENT_FORM, {
        variables: { formId: id }
    })

    useEffect(() => {
        if (query.data) {
            setEventData(query.data.eventFormsData.edges)
        }
    }, [query.data])

    useEffect(() => {
        if (queryForm.data) {
            const fields = queryForm.data.eventForm.fields

            const _columns = [
                {
                    title: 'Inscrição',
                    dataIndex: [''],
                    width: 300,
                    fixed: true,
                    render: (value, obj) => {
                        return (
                            <CrudColumns
                                title={
                                    obj.node.user?.name || obj.node.data?.name
                                }
                                image={obj.node.user?.avatar?.url}
                                text={[
                                    obj.node.user?.email ||
                                        obj.node.data?.email,
                                    `Data de criação: ${dayjs(
                                        new Date(
                                            obj.node?.createdAt ||
                                                obj.node?.user?.createdAt
                                        )
                                    ).format('DD MMM YYYY')}`
                                ]}
                            />
                        )
                    }
                }
            ]

            const utmColumns = [
                {
                    title: 'Origem',
                    dataIndex: ['node', 'context'],
                    width: 200,
                    render: (context, obj) => {
                        const url =
                            context?.url ||
                            _get(obj, 'node.data._context.origin', '')
                        const title =
                            context?.title ||
                            _get(obj, 'node.data._context.origin', '')

                        if (url) {
                            return (
                                <CrudColumns
                                    onlyText
                                    title="Origem"
                                    text={[
                                        <a href={url} target="_blank">
                                            {title}
                                        </a>
                                    ]}
                                />
                            )
                        }

                        return '---'
                    }
                },
                {
                    title: 'Utm Campaign',
                    dataIndex: ['node', 'context', 'search', 'utm_campaign'],
                    width: 200,
                    render: (utm_campaign, obj) => {
                        utm_campaign =
                            utm_campaign ||
                            _get(
                                obj,
                                'node.data._context.search.utm_campaign',
                                ''
                            )

                        return (
                            <CrudColumns
                                onlyText
                                title="UTM Campaign"
                                text={[
                                    <a target="_blank">
                                        {utm_campaign || `---`}
                                    </a>
                                ]}
                            />
                        )
                    }
                },
                {
                    title: 'Utm Source',
                    dataIndex: ['node', 'context', 'search', 'utm_source'],
                    width: 200,
                    render: (utm_source, obj) => {
                        utm_source =
                            utm_source ||
                            _get(
                                obj,
                                'node.data._context.search.utm_source',
                                ''
                            )

                        return (
                            <CrudColumns
                                onlyText
                                title="UTM Source"
                                text={[
                                    <a target="_blank">{utm_source || `---`}</a>
                                ]}
                            />
                        )
                    }
                },
                {
                    title: 'Utm Medium',
                    dataIndex: ['node', 'context', 'search', 'utm_medium'],
                    width: 200,
                    render: (utm_medium, obj) => {
                        utm_medium =
                            utm_medium ||
                            _get(
                                obj,
                                'node.data._context.search.utm_medium',
                                ''
                            )

                        return (
                            <CrudColumns
                                onlyText
                                title="UTM Medium"
                                text={[
                                    <a target="_blank">{utm_medium || `---`}</a>
                                ]}
                            />
                        )
                    }
                },

                {
                    title: 'Utm Content',
                    dataIndex: ['node', 'context', 'search', 'utm_content'],
                    width: 200,
                    render: (utm_content, obj) => {
                        utm_content =
                            utm_content ||
                            _get(
                                obj,
                                'node.data._context.search.utm_content',
                                ''
                            )

                        return (
                            <CrudColumns
                                onlyText
                                title="UTM Content"
                                text={[
                                    <a target="_blank">
                                        {utm_content || `---`}
                                    </a>
                                ]}
                            />
                        )
                    }
                },
                {
                    title: 'Utm Term',
                    dataIndex: ['node', 'context', 'search', 'utm_term'],
                    width: 200,
                    render: (utm_term, obj) => {
                        utm_term =
                            utm_term ||
                            _get(obj, 'node.data._context.search.utm_term', '')

                        return (
                            <CrudColumns
                                onlyText
                                title="UTM Term"
                                text={[
                                    <a target="_blank">{utm_term || `---`}</a>
                                ]}
                            />
                        )
                    }
                }
            ]

            fields.forEach((field) => {
                if (field.type !== 'session') {
                    _columns.push({
                        title: field.label,
                        dataIndex: ['node', 'data', field.name],
                        width: 200,
                        render: (_value) => {
                            let value = formatField(field, _value)
                            return (
                                <CrudColumns
                                    onlyText
                                    title={field.label}
                                    text={[value]}
                                />
                            )
                        }
                    })
                }
            })

            _columns.push(...utmColumns)

            setColumns(_columns)
        }
    }, [queryForm.data])

    if (!eventData && !query.loading) {
        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    width: '100%'
                }}
            >
                Nenhum dado encontrado
            </div>
        )
    }

    if (!columns) return null

    const handleExport = () => {
        const variables = {
            type: 'progressiveform',
            event: eventId,
            params: {
                id
            }
        }

        client.mutate({
            mutation: REQUEST_REPORT,
            update: addToList({
                list: 'reports',
                Type: 'Report'
            }),
            variables
        })
        history.push('/evento/' + eventId + '/reports')
    }

    return (
        <Layout
            style={{
                height: '100%'
            }}
        >
            <Content>
                <CrudList
                    header={{
                        title: 'Inscrições',
                        countNames: {
                            singular: 'Inscricão',
                            plural: 'Inscricões'
                        },
                        buttons: [
                            {
                                children: 'Exportar inscrições',
                                type: 'primary',
                                action: () => handleExport()
                            }
                        ]
                    }}
                    style={{
                        height: '15vh'
                    }}
                    columns={columns}
                    queryName={'eventFormsData'}
                    hideAction={'all'}
                    search={true}
                    scrollY={'100%'}
                    showHeader
                    {...query}
                />
            </Content>
        </Layout>
    )
}

export default ModalContent
