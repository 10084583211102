import React, {
    Dispatch,
    createContext,
    useCallback,
    useContext,
    useEffect
} from 'react'
import { useQuery, useMutation } from '@apollo/client'

import { removeObjectProps, resolveRelateds } from '@cms/events/utils'

import { merge } from 'lodash'

import { parseTheme } from '@cms/events/pages/Config/Streaming/pages/Theme/utils'
import { GET_WATCH_CONFIG } from '@cms/watch/graphql/queries/watchConfig'
import { UPDATE_WATCH_CONFIG } from '@cms/watch/graphql/mutations/watchConfig'

import { DEFAULT_THEME } from '../../../constants/theme'
import { useThemeBuilderContext } from '@cms/core/components/ThemeBuilder'
import { SECTIONS_MOCK } from '../constants/initialData'

type JsonType = Record<string, any>

type WatchContextProps = {
    theme: JsonType
    setTheme: Dispatch<JsonType>
    footer: JsonType
    setFooter: Dispatch<JsonType>
    configs: JsonType
    setConfigs: Dispatch<JsonType>
    scripts: JsonType
    setScripts: Dispatch<JsonType>
    home: JsonType
    setHome: Dispatch<JsonType>
    images: JsonType
    setImages: Dispatch<JsonType>
    save: () => Promise<void>
    loading: boolean
}

export const WatchContext = createContext<WatchContextProps>({
    theme: {},
    setTheme: () => {},
    footer: {},
    setFooter: () => {},
    configs: {},
    setConfigs: () => {},
    scripts: {},
    setScripts: () => {},
    home: {},
    setHome: () => {},
    images: {},
    setImages: () => {},
    save: () => undefined,
    loading: false
})

export const useWatchContext = () => useContext(WatchContext)

export const WatchProvider = ({ children }) => {
    const {
        images,
        theme,
        setTheme,
        home,
        setHome,
        configs,
        scripts,
        footer,
        setFooter,
        setImages,
        setConfigs,
        setScripts,
        setLoading
    } = useThemeBuilderContext()

    const { data, loading } = useQuery(GET_WATCH_CONFIG)

    const [updateWatchConfig] = useMutation(UPDATE_WATCH_CONFIG)

    useEffect(() => {
        if (data?.watchConfig) {
            const watchConfig = removeObjectProps({ ...data.watchConfig }, [
                '__typename'
            ])
            
            const watchTheme = parseTheme(watchConfig?.theme)

            setTheme(merge({}, DEFAULT_THEME, watchTheme))

            setScripts({ ...watchConfig.scripts })

            setFooter({ ...watchConfig.footerConfig })

            const { home, translation, loginBackgroundImage } = watchConfig

            setImages({ loginBackgroundImage })

            setHome(!home?.sections?.length ? SECTIONS_MOCK : home)

            setConfigs({
                translation
            })
        }
    }, [data])

    const save = useCallback(async () => {
        console.log(footer)
        await updateWatchConfig({
            variables: {
                theme: theme,
                home: home,
                scripts: scripts,
                footerConfig: footer,
                ...configs,
                ...resolveRelateds({ ...images }, ['loginBackgroundImage'])
            }
        })
    }, [theme, scripts, configs, home, images, footer])

    useEffect(() => setLoading(loading), [loading])

    return (
        <WatchContext.Provider
            value={{
                home,
                setHome,
                theme,
                setTheme,
                scripts,
                setScripts,
                configs,
                setConfigs,
                save,
                loading,
                setImages,
                images,
                footer,
                setFooter
            }}
        >
            {children}
        </WatchContext.Provider>
    )
}
