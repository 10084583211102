import React from 'react'

import { Col, Form, Input, Row, TreeSelect, message, InputNumber } from 'antd'
import { useApolloClient, useQuery } from '@apollo/client'

import { CrudModal, useCrudForm } from '@cms/core/components/Crud'

import {
    GET_WATCH_CATEGORIES_LIST_ORDENABLE,
    GET_WATCH_CATEGORY
} from '@cms/watch/graphql/queries/watchCategory'

import {
    CREATE_WATCH_CATEGORY,
    UPDATE_WATCH_CATEGORY
} from '@cms/watch/graphql/mutations/watchCategory'
import { addToList } from '@cms/core/graphql/utils'

import MediaUploader from '@cms/core/components/MediaUploader/graphql'
import useCrudTree from '@cms/core/components/Crud/hooks/useCrudTree'

const WatchContentsModal = ({ ...props }) => {
    const client = useApolloClient()

    const queryTopics = useQuery(GET_WATCH_CATEGORIES_LIST_ORDENABLE, {
        variables: { first: 9999 }
    })

    const [categoriesOptions, categoriesOptionsProps] = useCrudTree(
        queryTopics,
        {
            queryName: 'watchCategories'
        }
    )

    const [{ form, isEdit, getError }, modalProps] = useCrudForm({
        getData: async (id) => {
            if (!id) return

            const {
                data: { watchCategory }
            } = await client.query({
                query: GET_WATCH_CATEGORY,
                variables: { id }
            })

            const parent = watchCategory?.parent

            return {
                ...watchCategory,
                parent: parent?.id
            }
        },
        createData: async (formData) => {
            await client.mutate({
                mutation: CREATE_WATCH_CATEGORY,
                update: addToList({
                    list: 'watchCategorys',
                    Type: 'WatchContent'
                }),
                variables: {
                    input: {
                        ...formData
                    }
                }
            })

            message.success('Categoria cadastrada com sucesso!')
        },
        updateData: async (id, formData) => {
            await client.mutate({
                mutation: UPDATE_WATCH_CATEGORY,
                variables: {
                    id,
                    input: {
                        ...formData
                    }
                }
            })

            message.success('Categoria atualizada com sucesso!')
        }
    })

    return (
        <CrudModal
            title={isEdit ? 'Editar Categoria' : 'Nova Categoria'}
            {...modalProps}
            {...props}
        >
            <Form layout={'vertical'} form={form}>
                <Form.Item
                    label="Nome"
                    name="name"
                    rules={[{ required: true, message: 'Nome é obrigatório!' }]}
                    {...getError('name')}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Descrição"
                    name="description"
                    {...getError('description')}
                >
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            label="Imagem (900 x 400)"
                            name="image"
                            {...getError('image')}
                        >
                            <MediaUploader
                                options={{
                                    optimize: {
                                        resize: {
                                            width: 900,
                                            height: 500
                                        },
                                        quality: 80
                                    }
                                }}
                                type="image"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Banner (1920 x 630)"
                            name="imageBanner"
                        >
                            <MediaUploader type="image" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Highlight" name="imageHighlight">
                            <MediaUploader type="image" />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item label="Categoria Pai" name={['parent']}>
                    <TreeSelect
                        showSearch
                        style={{ width: '100%' }}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder="Please select"
                        allowClear
                        treeDefaultExpandAll
                        treeData={categoriesOptions()}
                        {...categoriesOptionsProps}
                    />
                </Form.Item>

                <Form.Item
                    label="Prioridade (Ordena os conteúdos do maior para o menor)"
                    name="priority"
                    initialValue={0}
                    {...getError('priority')}
                >
                    <InputNumber style={{ width: '100%' }} />
                </Form.Item>
            </Form>
        </CrudModal>
    )
}

export default WatchContentsModal
