import { darken, lighten, transparentize } from 'polished'

export const mountButtonTheme = (bgColor, highlight) => {
    const parseButton = {
        bgColor: highlight,
        textColor: bgColor,
        borderRadius: 50,
        hover: {
            transition: 0.2,
            bgColor: darken(0.14, highlight),
            textColor: bgColor,
            borderRadius: 50
        }
    }

    return parseButton
}

export const mountBoxTheme = (bgColor, text, highlight, logo) => {
    const parseBox = {
        bgColor,
        textColor: text,
        borderRadius: 50,
        logoColor: logo,
        button: mountButtonTheme(bgColor, highlight)
    }

    return parseBox
}

export const parseTheme = (data) => {
    if (!data || Object.keys(data).length === 0) return null
    const { primaryColor, dark, highlight, textColor, bgColor } = data
    const white = '#fff'
    const buttonStates = {
        bgColor: transparentize(0.5, primaryColor),
        textColor: textColor || white,
        highlight
    }

    const base = {
        bgColor: bgColor || dark,
        primaryColor,
        highlight,
        textColor: textColor || white,
        menu: {
            bgColor: dark,
            textColor: lighten(0.3, primaryColor),
            hover: buttonStates,
            active: buttonStates
        },
        box: {
            main: mountBoxTheme(primaryColor, white, highlight, 'white'),
            highlight: mountBoxTheme(highlight, white, white, 'white'),
            sponsor: mountBoxTheme(white, dark, primaryColor, 'default')
        },
        login: {
            textColor: textColor || white,
            bgColor: primaryColor,
            button: mountButtonTheme(white, highlight)
        },
        button: mountButtonTheme(white, highlight)
    }

    return base
}
