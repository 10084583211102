export const systemFonts = [
    'Arial',
    'Arial Black',
    'Verdana',
    'Comic Sans MS',
    'Impact',
    'Times New Roman'
]

export const googleFonts = [
    'Archivo',
    'Chakra Petch',
    'Figtree',
    'Golos Text',
    'Inter',
    'Jura',
    'Syne',
    'Barlow Condensed',
    'Lato',
    'Merriweather',
    'Montserrat',
    'Noto Sans JP',
    'Open Sans',
    'Open Sans Condensed',
    'Oswald',
    'Poppins',
    'PT Sans',
    'Quicksand',
    'Raleway',
    'Roboto',
    'Roboto Condensed',
    'Roboto Mono',
    'Roboto Slab',
    'Sora',
    'Space Grotesk',
    'Space Mono',
    'Source Sans Pro',
    'Teko',
    'Ubuntu'
]
