import React, { useMemo } from 'react'
import { Form, Select } from 'antd'
import { useQuery } from '@apollo/client'
import { GET_TALK_LIST_ORDERABLE } from '@cms/events/graphql/queries/talk'

/**
 * Escolher talks que serão convertidas em conteúdos para o TD Watch
 */
const SelectTaks = () => {
    const form = Form.useFormInstance()

    const { data, loading } = useQuery(GET_TALK_LIST_ORDERABLE, {
        variables: {
            eventId: form.getFieldValue('event')
        }
    })

    const options = useMemo(() => {
        if (!data || !data.talks) return []

        return data.talks.edges
            .map((edge) => edge.node)
            .filter((talk) => talk.video.url)
            .map((talk) => ({
                value: talk.id,
                label: talk.title
            }))
    }, [data])

    return (
        <Form.Item
            name="talks"
            rules={[
                {
                    required: true,
                    message: 'Selecione pelo menos uma palestra para importar'
                }
            ]}
        >
            <Select
                mode="multiple"
                loading={loading}
                placeholder={'Selecione as palestras que deseja importar'}
                options={options}
            />
        </Form.Item>
    )
}

export default SelectTaks
