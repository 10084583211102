import React, { useEffect, useState } from 'react'

import {
    Checkbox,
    Col,
    Form,
    Input,
    InputNumber,
    Row,
    Select,
    message
} from 'antd'
import { useApolloClient, useQuery } from '@apollo/client'

import {
    CrudModal,
    useCrudForm,
    useCrudSelect
} from '@cms/core/components/Crud'

import { GET_WATCH_CONTENT } from '@cms/watch/graphql/queries/watchContent'

import {
    CREATE_WATCH_CONTENT,
    UPDATE_WATCH_CONTENT
} from '@cms/watch/graphql/mutations/watchContent'
import { addToList } from '@cms/watch/graphql/utils'

import { GET_WATCH_CATEGORIES_LIST_ORDENABLE } from '@cms/watch/graphql/queries/watchCategory'
import { getWatchCategoryTreeData } from '@cms/watch/utils/common'

import SelectTree from '@cms/watch/components/SelectTree'
import { SectionCard } from '@cms/core/components/TDCards'

import { GET_INTEGRATIONS } from '@cms/core/graphql/queries/integrations'

import MediaUploader from '@cms/core/components/MediaUploader/graphql'
import { GET_WATCH_EXPERT_LIST_ORDERABLE } from '@cms/watch/graphql/queries/watchExpert'
import SelectSearch from '@cms/core/components/SelectSearch'

const WatchContentsModal = ({ ...props }) => {
    const { params = {}, onSaved } = props
    const { module = 'VIDEO' } = params

    const client = useApolloClient()

    const [players, setPlayers] = useState([])

    const { data: dataPlayers } = useQuery(GET_INTEGRATIONS, {
        variables: { category: 'players', appType: 'WATCH' }
    })

    const queryCategories = useQuery(GET_WATCH_CATEGORIES_LIST_ORDENABLE, {
        variables: { first: 99999, orderBy: { name: 'ASC' } }
    })

    const queryExperts = useQuery(GET_WATCH_EXPERT_LIST_ORDERABLE, {
        variables: { first: 99999, orderBy: { name: 'ASC' } }
    })

    const watchCategories = queryCategories?.data?.watchCategories?.edges?.map(
        ({ node }) => ({ ...node })
    )
    const treeData = getWatchCategoryTreeData(watchCategories)

    useEffect(() => {
        if (dataPlayers) {
            const players = dataPlayers.integrations.edges
            if (players?.length > 0) {
                setPlayers(
                    players.map(({ node }) => ({
                        label: node.name,
                        value: node.type
                    }))
                )
            } else {
                setPlayers([])
            }
        }
    }, [dataPlayers])

    const [{ form, isEdit, getError }, modalProps] = useCrudForm(
        {
            getData: async (id) => {
                if (!id) return

                const {
                    data: { watchContent }
                } = await client.query({
                    query: GET_WATCH_CONTENT,
                    variables: { id }
                })

                const categories = watchContent.categories
                const experts = watchContent.experts?.edges?.map(
                    (e) => e.node.id
                )

                return {
                    ...watchContent,
                    categories: categories?.map((m) => m.id),
                    video: {
                        ...watchContent.video,
                        player: watchContent.video.player || 'default'
                    },
                    experts
                }
            },
            createData: async (formData) => {
                const result = await client.mutate({
                    mutation: CREATE_WATCH_CONTENT,
                    update: addToList({
                        list: 'watchContents',
                        Type: 'WatchContent'
                    }),
                    variables: {
                        ...formData,
                        categories: formData.categories || [],
                        experts: formData.experts || [],
                        module: module
                    }
                })

                if (onSaved && result.data.createWatchContent)
                    onSaved(result.data.createWatchContent)

                message.success('Conteúdo cadastrado com sucesso!')
            },
            updateData: async (id, formData) => {
                const result = await client.mutate({
                    mutation: UPDATE_WATCH_CONTENT,
                    variables: {
                        id,
                        ...formData,
                        categories: formData.categories || [],
                        experts: formData.experts || []
                    }
                })

                if (onSaved && result.data.updateWatchContent)
                    onSaved(result.data.updateWatchContent)

                message.success('Conteúdo atualizado com sucesso!')
            }
        },
        [module]
    )

    const [expertsOptions, expertsSelectOptions] = useCrudSelect(queryExperts, {
        queryName: 'WatchExperts',
        additionalValues: form.getFieldValue('_experts')
    })

    return (
        <CrudModal
            width={700}
            title={isEdit ? 'Editar Conteúdo' : 'Novo Conteúdo'}
            {...modalProps}
            {...props}
        >
            <Form layout={'vertical'} form={form}>
                <SectionCard title="Geral">
                    <Form.Item
                        label="Título"
                        name="title"
                        rules={[
                            { required: true, message: 'Título é obrigatório!' }
                        ]}
                        {...getError('title')}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Descrição"
                        name="description"
                        {...getError('description')}
                    >
                        <Input.TextArea />
                    </Form.Item>

                    {module === 'VIDEO' && (
                        <Form.Item
                            label="Categorias"
                            name={['categories']}
                            rules={[
                                {
                                    required: true,
                                    message: 'Categoria é obrigatória!'
                                }
                            ]}
                            {...getError('categories')}
                        >
                            <SelectTree
                                placeholder="Selecione as categorias"
                                treeData={treeData}
                            />
                        </Form.Item>
                    )}

                    <Form.Item
                        label="Especialistas"
                        name={['experts']}
                        {...getError('experts')}
                    >
                        <SelectSearch {...expertsSelectOptions}>
                            {expertsOptions({
                                key: 'node.id',
                                label: 'node.name'
                            })}
                        </SelectSearch>
                    </Form.Item>

                    <Form.Item
                        label="Prioridade (Ordena os conteúdos do maior para o menor)"
                        name="priority"
                        initialValue={0}
                        {...getError('priority')}
                    >
                        <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                </SectionCard>

                <SectionCard title="Configuração de vídeo">
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                label="Player"
                                name={['video', 'player']}
                                initialValue={'default'}
                                {...getError('video.player')}
                            >
                                <Select>
                                    <Select.Option value="default">
                                        Padrão
                                    </Select.Option>
                                    {players.map((player) => (
                                        <Select.Option
                                            key={player.name}
                                            value={player.value}
                                        >
                                            {player.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={14}>
                            <Form.Item
                                label="Link do video (Youtube, Vimeo, mp4, m3u8)"
                                name={['video', 'url']}
                                rules={[
                                    {
                                        type: 'url',
                                        message: 'Url inválida'
                                    },
                                    {
                                        required: true,
                                        message: 'Título é obrigatório!'
                                    }
                                ]}
                                {...getError('video.url')}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Duração (minutos)"
                                name="duration"
                                {...getError('duration')}
                            >
                                <InputNumber />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item
                        label="Imagem (339 x 190)"
                        name="image"
                        {...getError('image')}
                    >
                        <MediaUploader
                            options={{
                                optimize: {
                                    resize: {
                                        width: 900,
                                        height: 500
                                    },
                                    quality: 80
                                }
                            }}
                            type="image"
                        />
                    </Form.Item>

                    <Form.Item name="featured" valuePropName="checked">
                        <Checkbox>Destaque</Checkbox>
                    </Form.Item>
                </SectionCard>
            </Form>
        </CrudModal>
    )
}

export default WatchContentsModal
